body, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !important;

}

h1,h2,h3,h4,h5,h6 {
  text-align: center;
  margin: 1em auto;
  font-weight: 300; 
}

img {
  width: 100%;
}

section {
  padding:  2em 5vw;
}

a:link, a:visited {color:#ff1493;}
a:hover {color:#0ce5e5;}
a:active {color:#78f6f6;}

header{
  /* background-color: black; */
  text-shadow: 2px 5px 5px #000000;
}

header h1 {font-size: xxx-large;}
header h2 {font-size: xx-large;}
header h3 {font-size: x-large;}

.headerimg {
  height: 90vh;
  /* background-color: black; */
  background-image: url('https://inyourhead.ru/files/Rorschach_Inkblot_002.jpg');
  /* background-attachment: fixed; */
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: 300;
  font-style: normal;
  font-size: x-large;
/*   -webkit-text-stroke-width: .3px;
-webkit-text-stroke-color: black; */
}

ul.navbar {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  height: 10vh;
  padding: 1em 5vw;
  align-items: center;
  background-color: black;
  color: white;
  position: sticky;
  top: 0;
  -webkit-positin: sticky;
  /* max-width: 1000px; */
  margin: 0;
}

main {
  text-align: center;
  max-width: 1000px;
  display: block;
  margin: 0 auto;
  text-align: left;
}
main h1, main h2, main h3 {
  color: #ff1493;
}


section.aboutme img {
  float: left;
  width: 30%;
  margin: 0 1em 1em 0;
  box-shadow: -3px 3px #ff1493;
  /* -webkit-filter: grayscale(100%); */
  /* filter: grayscale(100%); */
  -webkit-transition: -webkit-transform .5s,-webkit-filter .5s ease-in-out;
  transition: -webkit-transform .5s,-webkit-filter .5s ease-in-out;
  transition: transform .5s,filter .5s ease-in-out;
  transition: transform .5s,filter .5s ease-in-out,-webkit-transform .5s,-webkit-filter .5s ease-in-out;
}

.services h2 {
  display: inline;
}

.services ul, .english ul {
  list-style: disc;
}

.feedbackContainer {
  display: flex;
  align-items: flex-start;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  box-shadow: -3px 3px #000;
}

.video-container embed, .video-container iframe, .video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videos article {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.videos article div:last-child {
  grid-column: 1/-1;
}

.contacts > h1, .contacts > p {
  text-align: center;
}

.contacts p:nth-child(6) {
  margin-bottom: 2em;
}

img.social {
  width: 1.5em;
  margin: 0.5em;
  vertical-align: middle;
}

#booking iframe {
  margin:  1em 0;
}

#mobileDoc {
  display: none;
}

article.locations {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 1em;
  text-align: center;
  padding: 1em 0;
  margin-bottom: 1em;
}
article.locations > p {
  grid-column: 1/-1;
}


.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:  1em;
}

input {
  display: block;
  margin: 1em 0;
  width: 100%;
  background-color: -internal-light-dark-color(white, #ff1493);
}

textarea {
  overflow: auto;
  width: 100%;
  min-height: 20vh;
  margin-bottom: 1em;
}
/* 
button {
  width: 100%;
  padding: .5em;
  font-size: large;
  font-weight: bold;
  background: #fff!important; */
  /* color: #ff1493!important; */
/*   background-image: none!important;
  border: 1px solid black!important;
  border-radius: 0!important;
}

button:hover {
  box-shadow: -3px 3px grey!important;
  -webkit-transform: translate(3px,-3px);
  transform: translate(3px,-3px);
  outline: none;
  } */

  hr {
    margin: 6em 0 0 0;
  }

  .english {
    padding: 2em 1em;
  }

  .english p {
    text-align: center;
  }

  footer {
    background-color: black;
    color: white;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 1em 0; */
  }


  /* SEMANTIC UI KILL */

  .ui.styled.accordion, .ui.styled.accordion .accordion {
    border-radius: .28571429rem;
    background-color: #fff;
    -webkit-box-shadow: none; 
    box-shadow: none;
    border:  none;
  }
  .ui.bottom.attached.tab.segment.active {
    border: none;
    padding-top: 0;
    border-left: 1px solid grey;
    border-radius: 0;
  }

  .ui.accordion .accordion .title .dropdown.icon, .ui.accordion .title .dropdown.icon {
    font-family: Accordion;
    line-height: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    font-size: larger;
    color:  #ff1493;
  }

  .ui.text.vertical.menu {
    text-align: right;
    padding-right: 1em;
    border-right: 1px solid grey;
  }
  .ui.vertical.text.menu .item {
    margin: 0 0 1em 0;  
    padding-left: 0;
    padding-right: 0;
  }

  .ui.vertical.text.menu .item:hover {
    background-image: -webkit-gradient(linear,left top,right top,from(#ff1493b8),to(#ff1493b8));
    background-image: linear-gradient(90deg,#ff1493b8 0,#ff1493b8);
    background-size: 100% 75%;
    background-repeat: repeat-x;
    background-position: 0 80%;
  }
  .ui.vertical.menu {
    width: auto;
  }

  @media all and (max-width: 500px){
    header h1 {font-size: xx-large;}
    header h2 {font-size: x-large;}
    header h3 {font-size: large;}
    article.locations, .videos article {
      grid-template-columns: 1fr;
    }
    .navbar {
      display: none !important;
    }
    .headerimg {
      height: 100vh;
    }
    #mobileDoc {
      display: block;
    }
    section.aboutme img {
      float: left;
      width: 50%;
    }
  }
  @media all and (max-width: 400px){

    section.aboutme img {
      float: none;
      width: 100%;
    }
  }

